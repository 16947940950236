import React from 'react'
import ArticlesSection from '../components/sections/ArticlesSection'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import WeDoCtoBanner from '../components/sections/WeDoCtoBanner'

const PrivacyPolicy = ({ data: { contentfulPage: pageData } }) => {
  const {
    relatedPolicy: {
      lastUpdated,
      content: { raw: rawContent },
    },
    relatedTags: articlesData,
  } = pageData

  return (
    <Layout page={pageData} socialIcons showProgress showBelow={300}>
      <div className='container px-8'>
        <p>Last updated {lastUpdated}</p>
        {documentToReactComponents(JSON.parse(rawContent))}
      </div>
      {/*<WeDoCtoBanner />*/}
      {articlesData.length > 0 && <ArticlesSection articlesData={articlesData} />}
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulPage(pageLink: { eq: "/privacy-policy" }) {
      ...pageFields
    }
  }
`

export default PrivacyPolicy
